import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import T from '@/js/common/three/three.js'

export default class Simulator {
  constructor() {
    this._clock = null
    this._scene = null
    this._camera = null
    this._renderer = null
    this._mixer = null
    this._objects = {}
    this._animations = {}
    this._init()
  }
  _init() {
    this._clock = new THREE.Clock();
    this._renderer = T.Renderer.create(this._animation.bind(this))
    this._scene = T.Scene.create()
    this._camera = T.Camera.create(50, 50, 100)
    this._scene.add(this._camera)
    this._scene.add(T.Light.createAmbientLight())
    this._camera.add(T.Light.createDirectionalLight())
    this._controls = T.Control.createOrbit(this._camera, this._renderer)

    this._renderer.domElement.addEventListener( 'webglcontextrestored', () => {
      console.log('@ webglcontextrestored @')
      // https://github.com/mrdoob/three.js/issues/11435
      // cubeCamera.updateCubeMap( renderer, scene );
    } );
  }
  _load(name, path) {
    return new Promise(resolve => {
      T.Loader.loadGltf(path, struct => {
        const object = struct.scene
        this._objects[name] = object
        this._animations[name] = struct.animations
        this._scene.add(object)
        object.visible = false
        resolve(struct)
      })
    })
  }
  _animation(time) {
    TWEEN.update(time)
    this._controls.update()
    this._renderer.render(this._scene, this._camera)
    if (this._mixer) this._mixer.update(this._clock.getDelta())
  }
  load() {
    return Promise.all([
      this._load('wakering', '/object/wake-ring/wake-ring_v0.2.0.gltf').then(() => {
        const object = this._objects.wakering
        const color = 0xffcd82
        T.Mesh.setColor(object, 'base', color)
        T.Mesh.setColor(object, 'arm-top', color)
        T.Mesh.setColor(object, 'arm-right', color)
        T.Mesh.setColor(object, 'arm-left', color)
        T.Mesh.setColor(object, 'cap', color)
      }),
      this._load('hand_hole', '/object/hand/hand_hole_v0.1.0.gltf'),
      this._load('hand_back', '/object/hand/hand_back_v0.1.0.gltf'),
      this._load('handle', '/object/wake-ring/handle_v0.1.1.gltf'),  // 90E774
      this._load('hand_handle_front', '/object/hand/hand_handle_front_v0.1.0.gltf'),
      this._load('hand_handle_side', '/object/hand/hand_handle_side_v0.1.0.gltf'),
      this._load('hand_handle_back', '/object/hand/hand_handle_back_v0.1.0.gltf'),
    ])
  }
  start() {
    this._controls.autoRotate = true
    this._controls.autoRotateSpeed *= 0.5

    this._mixer = T.Animation.createMixer(this._objects.wakering)
    const animations = this._animations.wakering
    T.Animation.play(this._mixer, animations, 'arm-top_push', true)
    T.Animation.play(this._mixer, animations, 'cap_open', true)
    T.Animation.play(this._mixer, animations, 'card_flip_right', true)
    T.Animation.play(this._mixer, animations, 'card_flip_left', true)
    T.Animation.play(this._mixer, animations, 'arm-left_open', true)
    T.Animation.play(this._mixer, animations, 'arm-right_open', true)

    const hideAnimation = {
      before: { material: { transparent: true/*, depthTest: false*/ } },
      animation: { material: { opacity: 0 }, speed: 250 },
      after: { material: { transparent: false }, attrs: { visible: false } },
    }
    const showAnimation = {
      before: { attrs: { visible: true }, material: { /*depthTest: true,*/ transparent: true, opacity: 0 } },
      animation: { delay: 500, material: { opacity: 1 } },
      after: { material: { transparent: false } },
    }
    const transitsList = [
      {
        handle: hideAnimation,
        hand_handle_back: hideAnimation,
        wakering: { before: { attrs: { visible: true } } },
        hand_hole: showAnimation,
      }, {
        hand_hole: hideAnimation,
        hand_back: showAnimation,
      }, {
        hand_back: hideAnimation,
        hand_handle_front: showAnimation,
        handle: showAnimation,
      }, {
        hand_handle_front: hideAnimation,
        hand_handle_side: showAnimation,
      }, {
        hand_handle_side: hideAnimation,
        hand_handle_back: showAnimation,
      },
    ]
    let index = 0

    const next = () => {
      const transits = transitsList[index]
      if (++index >= transitsList.length) index = 0
      for (const name in transits) {
        const object = this._objects[name]
        const transit = transits[name]
        Object.assign(object, (transit.before || {}).attrs || {})
        let appliedAfter = false
        const applyAfter = () =>  {
          if (appliedAfter) return
          Object.assign(object, (transit.after || {}).attrs || {})
          appliedAfter = true
        }
        object.traverse(mesh => {
          if(!mesh.isMesh) return
          Object.assign(mesh.material, (transit.before || {}).material || {})
          if (transit.animation && transit.animation.material) {
            new TWEEN.Tween(mesh.material)
            .to(transit.animation.material, transit.animation.speed || 1000)
            .delay(transit.animation.delay || 0)
            // .easing(TWEEN.Easing.Exponential.In)
            .start()
            .onComplete(function(){
              Object.assign(mesh.material, (transit.after || {}).material || {})
              applyAfter()
            })
          } else {
            applyAfter()
          }
        })
      }
    }


    this._mixer.addEventListener('loop', event => {
      const action = event.action
      if (action._clip === animations[0]) {
        if (action._loopCount % 2 === 0) next()
      }
    })
    next()
  }
  getRendererElm() {
    return this._renderer.domElement
  }
  setSize(width, height) {
    this._renderer.setSize(width, height)
  }
  dispose() {
    this._renderer.dispose()
  }
}
