<template>
  <div class="page">
    <div ref="canvasField"></div>
    <div class="next-link">
      <w-flex align-center justify-space-evenly>
        <router-link to="/item/wake-ring/merit/">仕組みとメリット</router-link>
        <router-link to="/item/wake-ring/movie/">動画</router-link>
        <router-link to="/item/wake-ring/research/">有効性</router-link>
        <a href="https://minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">ショップ <w-icon>mdi mdi-open-in-new</w-icon></a>
      </w-flex>
    </div>
    <p class="next-comment">
      （本シミュレーションは、研究中の新形状のものです）<br><br>
      「
      <router-link to="/item/wake-ring/patent/">特許</router-link>
      」 を 活用していただける 企業様を 探しています
    </p>
  </div>
</template>

<script>
import Simulator from '@/js/page/item/wake-ring/how-to-use/simulator.js'
import PgeFitter from '@/js/common/page/fit/page-fitter.js'
export default {
  data() {
    return {
    }
  },
  async mounted() {
    this.simulator = new Simulator()
    const elm = this.simulator.getRendererElm()
    this.$refs.canvasField.appendChild(elm)
    this.pageFitter = new PgeFitter(elm, size => {
      this.simulator.setSize(size, size)
    })
    await this.simulator.load()
    this.simulator.start()
  },
  beforeUnmount() {
    this.pageFitter.clear()
    if (this.simulator._renderer) {
      this.simulator.dispose()
    }
  },
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
}
</style>
