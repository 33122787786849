import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

class Renderer {
  static create(animationFunc) {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      // alpha: true
    })
    renderer.setClearColor(new THREE.Color(0xffffff))
    renderer.setSize(500, 500)
    renderer.setAnimationLoop(animationFunc)
    return renderer
  }
}

class Scene {
  static create() {
    return new THREE.Scene()
  }
}

class Loader {
  static loadGltf(path, callback, color = null, isComputingVertexNormals = false) {
    const loader = new GLTFLoader()
    loader.load(path, gltf => {
      gltf.scene.traverse(object => {
        if (object.isMesh) {
          const mesh = object
          if (isComputingVertexNormals) {
            mesh.geometry.computeVertexNormals()
          }
          if (color) {
            mesh.material = new THREE.MeshLambertMaterial({ color })
          }
        }
      })
      callback(gltf)
    }, undefined, error => {
      console.error(error)
    })
  }
  static loadJson(path, callback) {
    const loader = new THREE.ObjectLoader()
    loader.load(path, object => {
      callback(object)
    })
  }
}

class Light {
  static createAmbientLight(color = 0x555555) {
    return new THREE.AmbientLight(color)
  }
  static createDirectionalLight() {
    const light = new THREE.DirectionalLight()
    light.position.set(0, 0, 1)
    return light
  }
  static createSpotLight(x = 0, y = 0, z = 0, color = 0xffffff) {
    const light = new THREE.SpotLight(color)
    light.position.set(x, y, z)
    return light
  }
}

class Camera {
  static create(x = 0, y = 0, z = 0) {
    const camera = new THREE.PerspectiveCamera()
    camera.up.set(0, 0, 1)
    camera.position.set(x, y, z)
    // camera.lookAt(scene.position)
    return camera
  }
}

class Control {
  static createOrbit(camera, renderer) {
    return new OrbitControls(camera, renderer.domElement)
  }
}

class Animation {
  static createMixer(scene) {
    return new THREE.AnimationMixer(scene)
  }
  static addEventListenerOnce(mixer, action, callback) {
    const call = event => {
      if (event.action === action) {
        mixer.removeEventListener('loop', call)
        mixer.removeEventListener('finished', call)
        callback()
      }
    }
    mixer.addEventListener('loop', call)
    mixer.addEventListener('finished', call)
  }
  static play(mixer, animations, name, goOrBack) {
    return new Promise(resolve => {
      const clip = THREE.AnimationClip.findByName(animations, name)
      const action = mixer.clipAction(clip)
      if (goOrBack) action.reset()
      else action.paused = false
      action.setDuration(goOrBack ? 10 : -10)
      // action.setLoop(THREE.LoopOnce)
      action.setLoop(THREE.LoopPingPong)
      action.clampWhenFinished = goOrBack
      Animation.addEventListenerOnce(mixer, action, resolve)
      action.play()
    })
  }
  static hide(scene, name) {
    const object = scene.getObjectByName(name)
    object.visible = false
  }
}

class Mesh {
  static setColor(struct, name, color) {
    const object = struct.getObjectByName(name)
    if (object && object.isMesh) {
      const mesh = object
      mesh.material = new THREE.MeshLambertMaterial({ color })
    }
  }
}

export default class Three {
  static Renderer = Renderer
  static Scene = Scene
  static Loader = Loader
  static Light = Light
  static Camera = Camera
  static Control = Control
  static Animation = Animation
  static Mesh = Mesh
}
