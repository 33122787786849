export default class PageFitter {
  constructor(targetElm, callback) {
    this._targetElm = targetElm
    this._parentElm = targetElm.parentElement
    this._callback = callback
    this._stockedDisplay = null
    this._init()
  }
  _init() {
    this._targetElm.style.margin = '0 auto'
    window.addEventListener('resize', this.fit.bind(this))
    this.fit()
  }
  _hide() {
    const style = this._targetElm.style
    this._stockedDisplay = style.display
    style.display = 'none'
  }
  _show() {
    const style = this._targetElm.style
    style.display = this._stockedDisplay
  }
  clear() {
    window.removeEventListener('resize', this.fit.bind(this))
  }
  fit() {
    this._hide()
    const topRect = window.document.documentElement.getBoundingClientRect()
    const rect = this._parentElm.getBoundingClientRect()
    const size = Math.min(rect.width, window.innerHeight - (rect.top - topRect.top))
    this._show()
    this._callback(size)
  }
}
